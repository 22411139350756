import styled from "styled-components";
import { theme } from "@by-miles/bymiles-styles";

const { grid: { spacing }, tints, primaryColours } = theme

export const DisasterRecoveryContentStyles = styled.div<{showContactDetails: boolean}>`
  .content-wrapper {
    display: flex;
    flex-direction: column;
    background-color: ${tints.lightAubergine};
    padding: ${spacing.l}px ${spacing.md}px ${spacing.xl}px;
    .content-header {
      ${({ showContactDetails }) => !showContactDetails && "max-width: 539px;"}
      .title {
        margin-top: ${spacing.base}px;
      }
      .info {
        margin: ${spacing.s}px 0 ${spacing.base}px;
        color: ${primaryColours.aubergine.main};
      }
    }
    .contact-details {
      background-color: ${primaryColours.white.main};
      padding: ${spacing.md}px ${spacing.base}px;
      .subtitle {
        margin-bottom: ${spacing.s}px;
      }
      .text {
        color: ${primaryColours.aubergine.main};
      }
      .number {
        margin: ${spacing.s}px 0 ${spacing.xs}px;
      }
      .button {
        margin: ${spacing.base}px 0 ${spacing.base}px;
      }
    }
    .bold {
      font-weight: 700;
    }
  }

  @media (min-width: ${theme.grid.resolution.tablet.width}px) {

  }
  @media (min-width: ${theme.grid.resolution.desktop.width}px) {
    .content-wrapper {
      max-width: ${theme.grid.resolution.desktop.width}px;
      flex-direction: row;
      gap: ${spacing.md}px;
      padding: ${spacing.xl}px 168px ${spacing.xxl}px;
      .content-header {
        flex: 1;
        ${({ showContactDetails }) => !showContactDetails && "margin-left: 144px;"}
        .title {
          margin-top: ${spacing.md}px;
        }
        .info {
          margin: ${spacing.base}px 0 ${spacing.md}px;
        }
      }
      .contact-details {
        flex: 1;
        .button {
          margin-bottom: ${spacing.md}px; 
        }
      }
    }
  }
`