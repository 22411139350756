import React from 'react'
import { ButtonPrimary, H2, H3, H4, ParagraphBody, Banner, BannerThemes } from '@by-miles/bymiles-styles'
import { DisasterRecoveryContentStyles } from './DisasterRecoveryContentStyles'
import { ReactComponent as ErrorImage } from '../../icons/illustrations/error.svg';

export const DisasterRecoveryContent = () => {
  const showContactDetails = true
  return (
    <DisasterRecoveryContentStyles showContactDetails={showContactDetails}>
      <div className="content-wrapper">
        <div className="content-header">
        <ErrorImage />
          <H2 className="title">We’ll be back soon.</H2>
          <ParagraphBody className="info">Like the highways, our site needs a little maintenance now and then. For now, you won’t be able to login, or get a quote. (But don’t worry - it won’t take as long as roadworks.)</ParagraphBody>
        </div>
        {showContactDetails && (
          <div className="contact-details">
            <H3 className="subtitle">Need help?</H3>
            <ParagraphBody className="text">If you need to get in touch just tap the button below to send us an email or call the number below.</ParagraphBody>
            <H4 className="number">0340 088 3838</H4>
            <ParagraphBody className="text">Call us from 9am - 5pm on weekdays.</ParagraphBody>
            <ButtonPrimary
              label="Email us"
              onClick={() => window.open('mailto:hello@bymiles.co.uk')}
              iconRight="IconArrowRight"
            />
            <Banner 
              theme={BannerThemes.cyanTint}
              borderLeft
              message={
                <div>
                  <H4>Need to make a claim?</H4>
                  <ParagraphBody className="text">
                    {'Please call our 24/7 claims line on '}
                    <span className="bold">0330 088 3838</span>
                    {'.'}
                  </ParagraphBody>
                </div>
              }
            />
          </div>
        )}
      </div>
    </DisasterRecoveryContentStyles>
  )
}