const brochure = 'https://www.bymiles.co.uk';

export const footerLinks = [
    {
      label: "Terms of business",
      href: `${brochure}/terms-of-business`,
    },
    {
      label: "Privacy notice",
      href: `${brochure}/privacy-notice`,
    },
    {
      label: "Information security",
      href: `${brochure}/information-security`,
    },
    {
      label: "Cookies",
      href: `${brochure}/cookies`,
    },
    {
      label: "Terms of use",
      href: `${brochure}/terms`,
    },
  ];