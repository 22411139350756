import './App.scss'
import React from 'react';
import { DisasterRecoveryContent } from './components/DisasterRecoveryContent';
import { Header, Footer } from '@by-miles/bymiles-styles';
import { footerLinks } from './utils/footerLinks'

function App() {
  return (
    <div className="app">
      <Header buttonText="" showButton={false} />
      <DisasterRecoveryContent />
      <Footer 
        text="Authorised and regulated by the Financial Conduct Authority (FCA) number 773046. By Miles Ltd, Churchill Court, Westmoreland Road, Bromley BR1 1DP is a registered company number 09498559 incorporated in the United Kingdom."
        links={footerLinks}
      />
    </div>
  );
}

export default App;
